<template>
  <div>
    <header
      id="header-modo-ead"
      class="fd-app-mode-ead-header"
    >
      <div
        id="divBannerMeusCursos"
        class="fd-app-mode-ead-header-bg"
        :style="{
          backgroundImage:
            'url(' + $store.state.fastPlataforma.banner_plataforma + ')',
          backgroundColor: '#000',
        }"
      />
      <div class="fd-app-mode-ead-header-overlay">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-sm-12 col-md-12 col-lg-8">
              <h1
                :style="{
                  color: $store.state.fastPersonalizacao.titulo_banner_color
                    ? $store.state.fastPersonalizacao.titulo_banner_color
                    : '#fff',
                  textShadow: '1px 1px #000',
                }"
              >
                {{
                  $store.state.fastPersonalizacao.titulo_banner
                    ? $store.state.fastPersonalizacao.titulo_banner
                    : ""
                }}
              </h1>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <mural-avisos />
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb fast-menu-lives-aluno-fast">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                :href="'/plataforma/' + $route.params.id_plataforma"
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Modo aluno</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/turmas'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/turmas')"
              >Minhas turmas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + fastTurma.id_turma + '?matricula=' + base64encode(base64decode(getUrlParameter('matricula')))"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + fastTurma.id_turma + '?matricula=' + base64encode(base64decode(getUrlParameter('matricula'))))"
              >Turma {{ fastTurma.sigla_turma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#">{{ fastPrograma.sigla_programa }}</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-ead-content">
        <div class="container">
          <div class="row">
            <transition name="slide-fade">
              <component
                :is="HomeInternoAlunoMenuLateral"
                v-if="!isMobile()"
              />
            </transition>
            <div :class="isMobile() ? 'col-sm-12 col-md-12 col-lg-12' : 'col-sm-12 col-md-12 col-lg-11 fd-mode-ead-content-body'">
              <div class="fd-mode-ead-content mt-0 fast-iuea-ml-20">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <div class="row">

                        <transition name="slide-fade">
                          <div v-if="fastNavegacao.exibirModulos" class="col-sm-12 col-md-12 col-lg-12">
                            <!-- fd-app-welcome -->
                            <section
                              class="fd-app-welcome text-center fast-plataforma-iuea-hide"
                            >
                              <h1
                                class="aluno_font_color"
                              >
                                Turma
                                <span v-if="fastPrograma.carregandoUnidades">...</span>
                                <span v-else>{{ fastTurma.sigla_turma }}</span>
                              </h1>

                              <h3                           
                                v-if="fastPrograma.carregandoUnidades"
                                class="aluno_font_color mt-4"
                              >
                                Carregando...                              
                              </h3>
                              <h3
                                v-else
                                class="aluno_font_color mt-4"
                              >
                                {{ fastTurma.nome_curso }} - {{ fastPrograma.sigla_programa }}
                              </h3>
                            
                              <div class="col-12 mt-4">
                                <b-button
                                  variant="secondary"
                                  class="ml-1 mr-1 mb-2"
                                  disabled
                                >
                                  <b-icon-chat-quote /> Fórum <span class="badge badge-light">0</span>
                                </b-button>
                                <b-button
                                  variant="secondary"
                                  class="ml-1 mr-1 mb-2"
                                  disabled
                                >
                                  <b-icon-people /> Pátio virtual <span class="badge badge-light">0</span>
                                </b-button>
                                <b-button
                                  variant="secondary"
                                  class="ml-1 mr-1 mb-2"
                                  disabled
                                >
                                  <b-icon-camera-video /> Aulas ao vivo <span class="badge badge-light">0</span>
                                </b-button>
                                <b-button
                                v-if="fastAvaliacoesTurma.length"
                                @click.prevent="alteraFastNavegacao('exibirAvaliacoesTurma')"
                                variant="primary"
                                class="ml-1 mr-1 mb-2"
                              >
                                <b-icon-stopwatch /> Provas <span class="badge badge-light">{{fastAvaliacoesTurma.length}}</span>
                              </b-button>
                              <b-button
                                v-else
                                variant="secondary"
                                class="ml-1 mr-1 mb-2"
                                disabled
                              >
                                <b-icon-stopwatch /> Provas <span class="badge badge-light">0</span>
                              </b-button>
                                <b-button
                                  variant="secondary"
                                  class="ml-1 mr-1 mb-2"
                                  disabled
                                >
                                  <b-icon-calendar2-week /> Calendário <span class="badge badge-light">0</span>
                                </b-button>
                              </div>
                              <div
                                class="fd-welcome-form-effect fast-plataforma-iuea-hide"
                              >
                                <img
                                  :src="
                                    require('@/assets/images/form-effect-01.png')
                                  "
                                  class="mt-2"
                                >
                              </div>
                            </section>
                            <!-- /fd-app-welcome -->
                                                      
                            <div class="col-12 mt-4">                
                              <div
                                v-if="fastPrograma.carregandoUnidades"
                                class="row justify-content-around text-center"
                              >
                                <p>Carregando...</p>
                              </div>
                              <div
                                v-else-if="fastPrograma.unidades.length"
                                class="row"
                              >
                                <div class="col-12 text-center mt-4 mb-4">
                                  <h4 v-if="fastPrograma.unidades.length > 1" class="text-secondary">
                                    {{ fastPrograma.unidades.length }} unidades curriculares encontradas
                                  </h4>
                                  <h4 v-else-if="fastPrograma.unidades.length == 1" class="text-secondary">
                                    1 unidade curricular encontrada
                                  </h4>
                                </div>
                                <div
                                  v-for="unidade in fastPrograma.unidades"
                                  :key="unidade.id_programa_turma_unidade"
                                  class="col-sm-12 col-md-12 col-lg-4 mb-4"
                                >
                                  <div class="card">
                                    <img
                                      class="card-img-top"
                                      :src="imageSitePlataformaImg"
                                      alt="Card image cap"
                                    >
                                    <div class="card-body">
                                      <h5 class="card-title text-center">
                                        {{ unidade.nome_curso }}
                                      </h5>
                                      <a
                                        :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos/curso/' + unidade.id_curso + '?turma=' + base64encode(parseInt($route.params.id_turma)) + '&programa=' + base64encode(parseInt($route.params.id_programa_turma)) + '&matricula=' + base64encode(base64decode(getUrlParameter('matricula')))"
                                        class="btn btn-primary btn-block"
                                      > <b-icon-box-arrow-in-left /> Acessar conteúdo</a>

                                        <button v-if="unidade.avaliacao_configurada && unidade.id_secretaria_avaliacao_principal" :class="'btn btn-primary btn-block'"
                                        @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + fastTurma.id_turma + '/programa/' + fastPrograma.id_programa_turma + '/avaliacao/' + unidade.id_secretaria_avaliacao_principal + '?tipo=A&matricula=' + base64encode(base64decode(getUrlParameter('matricula'))))"
                                        ><b-icon-list-check /> Avaliação</button>

                                        <button v-if="unidade.recuperacao_configurada && unidade.id_secretaria_avaliacao_recuperacao && unidade.id_secretaria_avaliacao_aluno_avaliacao && !unidade.aprovado_avaliacao" :class="'btn btn-primary btn-block'"
                                        @click.prevent="acessarRecuperacao(unidade)"
                                        ><b-icon-stickies /> Recuperação</button>
                                      
                                    </div>
                                  </div>
                                </div>                              
                              </div>
                              <div
                                v-else
                                class="row justify-content-around text-center"
                              >
                                <p>Nenhuma unidade curricular encontrada no módulo</p>
                              </div> 
                            </div>
                            <div class="col-12 mt-4">
                              <a
                                class="btn btn-red-hollow fast-iuea-ml-20"
                                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + fastTurma.id_turma + '?matricula=' + base64encode(base64decode(getUrlParameter('matricula')))"
                                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + fastTurma.id_turma + '?matricula=' + base64encode(base64decode(getUrlParameter('matricula'))))"
                              >
                                <b-icon-arrow-return-left /> Voltar
                              </a>
                            </div>
                          </div>
                        </transition>

                        <transition name="slide-fade">
                          <div v-if="fastNavegacao.exibirAvaliacoesTurma" class="col-12 mt-4">
                            <div class="row mb-4">
                              <div class="col-12 text-center mb-2">
                                <span v-if="fastAvaliacoesTurma.length" class="text-xs font-weight-bold text-secondary text-uppercase" >Avaliações encontradas</span>
                                <span v-else class="text-xs font-weight-bold text-secondary text-uppercase">Nenhuma avaliação encontrada</span>
                              </div>
                              <div v-for="(e, index) in fastAvaliacoesTurma" :key="index" class="col-sm-12 col-md-12 col-lg-6 p-2 mt-2">              
                                <b-card no-body>
                                  <b-row no-gutters>
                                    <b-col md="6">
                                      <b-card-img :src="require('@/assets/images/app/plataforma-img-02.jpg')" alt="Image" class="rounded-0"></b-card-img>
                                    </b-col>
                                    <b-col md="6" class="d-flex align-items-center">
                                      <b-card-body :title="e.nome_curso ? e.nome_curso : e.nome_curso_mae" class="p-4">                        
                                        <div class="mt-2 mb-2">
                                          <button v-if="e.avaliacao_configurada && e.id_secretaria_avaliacao_principal" :class="e.id_secretaria_avaliacao_aluno_avaliacao ? 'btn btn-primary text-white p-2 mt-2' : 'btn btn-sm btn-secondary text-white p-2 mt-2'"
                                          @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + fastTurma.id_turma + '/programa/' + e.id_programa_turma + '/avaliacao/' + e.id_secretaria_avaliacao_principal + '?tipo=A&matricula=' + base64encode(base64decode(getUrlParameter('matricula'))))"
                                          ><b-icon-list-check /> Ir para avaliação</button>
                                        </div>
                                        <div>
                                          <button v-if="e.recuperacao_configurada && e.id_secretaria_avaliacao_recuperacao && e.id_secretaria_avaliacao_aluno_avaliacao && !e.aprovado_avaliacao" :class="e.id_secretaria_avaliacao_aluno_recuperacao ? 'btn btn-primary text-white p-2 mt-2' : 'btn btn-secondary text-white p-2 mt-2'"
                                          @click.prevent="acessarRecuperacao(e)"
                                          ><b-icon-stickies /> Ir para recuperação</button>
                                        </div>
                                      </b-card-body>
                                    </b-col>
                                  </b-row>
                                </b-card>
                              </div>
                            </div>
                            <div class="row mt-4">
                              <div class="col-12">
                                <a
                                  class="btn btn-red-hollow text-danger"
                                  @click.prevent="alteraFastNavegacao('exibirModulos')"
                                >
                                  <b-icon-arrow-return-left />
                                  Voltar
                                </a>
                              </div>
                            </div>
                          </div>
                        </transition>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /fd-wrap -->
    <!-- Modal -->
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import muralAvisos from "../components/MuralAvisos";
import imageSitePlataformaImg from "@/assets/images/app/plataforma-img.jpg";

export default {
  name: "HomeInternoAlunoTurmaPrograma",
  components: {
    muralAvisos
  },
  mixins: [methods],
  data: function () {
    return {
      imageSitePlataformaImg,
      fastTemplate: settings.fastTemplate,
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",    
      fastNavegacao: {
        exibirModulos: true,
        exibirAvaliacoesTurma: false,
      },
      fastTurma: {
        modulos: [],
        carregandoModulos: true
      },  
      fastPrograma: {
        id_programa_turma: 0,
        sigla_programa: "",
        unidades: [],
        carregandoUnidades: true
      },
      // Avaliacoes
      fastAvaliacoesTurma: [],
      
    };
  },
  computed: {
    HomeInternoAlunoMenuLateral() {
      return () =>
        import(
          `@/templates/${this.fastTemplate}/components/HomeInternoAlunoMenuLateral.vue`
        );
    },
  },
  mounted: function () {
    if (this.$route.params.id_plataforma && this.$route.params.id_turma && this.$route.params.id_programa_turma && this.getUrlParameter("matricula")) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'aluno')
        .then(() => {
          this.getFastCursosPrograma(this.$route.params.id_plataforma, this.$route.params.id_programa_turma)
          this.getFastMatriculaAluno(this.$route.params.id_plataforma, this.base64decode(this.getUrlParameter("matricula")))
          this.getFastAvaliacoesTurma(this.$route.params.id_plataforma, this.$route.params.id_turma)
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    } else {
        this.exibeToasty("Sem permissões", "error");
        this.$store.state.fastCarregando = false;
    }
  },
  methods: { 
    async getFastMatriculaAluno(id_plataforma, id_matricula){
      this.promiseGetFastApi("api/fast_secretaria_matricula_aluno/lista_aluno", "id_matricula=" + id_matricula + "&id_plataforma=" + id_plataforma).then(obj => {
        console.log("getFastMatriculaAluno", obj)
        this.fastMatriculaAluno = obj;
        this.getFastTurma(this.$route.params.id_plataforma, this.$route.params.id_turma)
      }).catch(e => {
        console.log(e);
      })
    },
    async getFastTurma(id_plataforma, id_turma) {
      this.fastTurma.carregandoModulos = true;
      this.promiseGetFastApi("api/fast_secretaria_turma/lista", "id_plataforma=" + id_plataforma + "&id_turma=" + id_turma).then(obj => {
        console.log("getFastTurma", obj)
        if (obj.length) {
          obj[0].modulos = []; 
          obj[0].carregandoModulos = true         
          this.fastTurma = obj[0]
        } else {
          this.fastTurma.carregandoModulos = false;  
        }
             
      }).then(() => {
        this.promiseGetFastApi("api/fast_secretaria_turma_programa/lista", "id_plataforma=" + id_plataforma + "&id_turma=" + id_turma).then(obj => {
            console.log("getFastMódulos", obj)
            if (obj.length) {          
              obj.forEach((m, index) => {                
                if (m.id_programa_turma == this.$route.params.id_programa_turma) {
                  this.fastPrograma.id_programa_turma = m.id_programa_turma
                  this.fastPrograma.sigla_programa = m.sigla_programa
                }
                this.fastMatriculaAluno.forEach(m2 => {
                  if (m.id_programa_turma == m2.id_programa_turma) {
                    obj[index].permitido = true                                        
                  }
                })
              })   
              this.fastTurma.modulos = obj
              
            } else {
              this.fastTurma.modulos = [];
            }          
            this.fastTurma.carregandoModulos = false;  
          }).catch(e => {
            console.log(e);
          })
      })      
      .catch(e => {
        console.log(e);
      })
    },  
    async getFastCursosPrograma(id_plataforma, id_programa_turma) {
      this.fastPrograma.carregandoUnidades = true;
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_secretaria_turma_programa_unidade/lista_avs?id_plataforma=" +
            id_plataforma +
            "&id_programa_turma=" +
            id_programa_turma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        console.log("api/fast_secretaria_turma_programa_unidade/lista_avs", json)
        let obj = Array.from(json);
        
        if (obj.length > 0) {
          obj.forEach((e) => {
            if (e.data_inicio) e.data_inicio = e.data_inicio.split("T")[0];
            if (e.data_final) e.data_final = e.data_final.split("T")[0];            
          });

          this.stepList = obj.map((e) => {
            return e.sequencia + " - " + e.nome_curso;
          });
          this.fastPrograma.unidades = obj;
        } else {
          this.fastPrograma.unidades = [];
        }

        

        this.fastPrograma.carregandoUnidades = false;
        console.log("getFastCursosPrograma", this.fastPrograma)
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getFastAvaliacoesTurma(id_plataforma, id_turma){
      this.promiseGetFastApi("api/fast_secretaria_avaliacao_aluno/avaliacoes_turma", "id_turma=" + id_turma + "&id_plataforma=" + id_plataforma)
      .then(res => {
        console.log("getFastAvaliacoesTurma", res)
        this.fastAvaliacoesTurma = res.filter(e => e.id_programa_turma == this.$route.params.id_programa_turma)
      }).catch(e => {
        console.log(e);
      })
    },
    async acessarRecuperacao(e){
      
      this.$router.push('/plataforma/' + this.$route.params.id_plataforma + '/aluno/turma/' + this.fastTurma.id_turma + '/programa/' + this.fastPrograma.id_programa_turma + '/avaliacao/' + e.id_secretaria_avaliacao_recuperacao + '?tipo=R&matricula=' + this.base64encode(this.base64decode(this.getUrlParameter('matricula'))))      
    },
    validaUnidadePreRequisito(unidade){
      let liberada = false
      if (unidade.id_pai) {
        this.fastPrograma.unidades.forEach(u => {
          if (unidade.id_pai == u.id_secretaria_curso_programa_unidade) {
            console.log("A Unidade #" + u.id_curso + " " + u.nome_curso + " deve estar finalizado")

            if (u.avaliacao_configurada) {
              console.log("Unidade com avaliação")
              if (u.aprovado_avaliacao || u.aprovado_recuperacao) {
                console.log("Aluno aprovou na avaliação")
                liberada = true
              } else {
                console.log("Aluno não aprovou na avaliação")
              }
            } else {
              console.log("Unidade sem avaliação")

              if (u.unidade_encerrada) {
                liberada = true
                console.log("Unidade encerrada")
              } else {
                console.log("Unidade não encerrada")
              }
              
            }
          }
        })
      } else {
        liberada = true
      }

      return liberada
      
    }
  },
};
</script>

<style></style>
